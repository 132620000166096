.container{
    max-width: auto;
    margin: 0 auto;
}

.picturesize {
    height: 22rem;
    width: 40rem;
    border-radius: 1.875rem;
}

.content-con{
    display: flex;
    justify-content:space-around; 
    
}

.content-l img {
    width: 100%;
    border-radius: 30px;
    margin: auto;
}

.content-r  {
    width:auto;
    padding: 2rem;  
    text-align: left;
}

.responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
    display: block;
  margin-left: auto;
  margin-right: auto;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .picturepartnersize {
    height: 100px;
    width: 100px;
}

@media screen and (max-width: 480px){
    .content-con{
     flex-direction: column;
    }
}