/* .Pos_nav {
    display: flex;
    justify-content: space-between;
    background-color: #5a5a5a ;
    height: 100px;
    align-items: center;
} */

.container {
    max-width: auto;
    margin:  0 auto;
}
.header{
    background: #333;
    height: 100px;
}
.header-con{
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}
.logo-container a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    align-items: center;
}
.menu{
    display: flex;
    list-style-type: none;
}

.menu li {
    padding-right: 2rem;
}

.menu li a{
    text-decoration: none;
    color: #fff;
}

.mobile-menu{
    color: #000;
    display: none;
}
@media screen and (max-width: 480px){
    .mobile-menu{
        display: block;
        color: #fff;
        font-size: 2rem;
    }
    .header{
        padding:0 2rem ;
    }
    .menu {
        width: 100%;
        height: auto;
        display: flex;
        position: absolute;
        flex-direction: column;
        top: 84px;
        left: -100%;
         opacity: 0;
        transition: 0.5s ease;
    }
    .menu.active{
     background: #5a5a5a;
     left: 0;
     opacity: 1;
     z-index: 1;
     padding-left: 0rem;
     align-content:center;
     transition: 0.5s ease;
    }
    .menu li {
        padding-right: 0rem;
    }
    .menu-link{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vw;
        padding: 10px 0;
    }
}

.logo {
    height: 80px;
    width: 5rem;
}

.logo_text {
    display: flex;
    align-items: center;

}


.footer_pos {
    background-color: #041B2D;
    /* position: fixed; */
    left: 0;
    bottom: 0;
    right: 0;
    height: 213px;
}

.footer_text_header {
    font-size: 20px;
    font-weight: 500;

}

.footer_text_size {
    font-size: 14px;

}

.footer_icon_pos {
    margin-top: 55px;
    margin-left: 25px;
    position: fixed;
}

.footer_text_pos {
    font-size: 15px;
    display: grid;
    align-items: center;
    margin-top: 50px;
    margin-left: 60px;
    position: fixed;
}

.footer_text {
    font-size: calc(60% + 0.8vmin);
    display: flex;
    align-items: center;
}

.flexbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    margin: 0px -10px;
}

.item {
    box-sizing: border-box;
    width: 50%;
    margin-bottom: 20px;
    padding: 0px 10px;
}

.content {
    color: #ffffff;
    /* font-weight: 500; */
    text-align: start;
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
}

.picturesize {
    height: 339px;
    width: 40rem;
    border-radius: 30px;
}

.picturepartnersize {
    height: 100px;
    width: 100px;
}

.border {
    height: 339px;
    width: 40rem;
}

.card {
    height: 15.625rem;
    width: 15.625rem;
    
}

.card:hover {
    transform: scale(1.03);
}

.iconsize{
    height: 110px;
    width: 110px;
}


