.container{
    max-width: auto;
    margin: 0 auto;
}

.content-con{
    display: flex;
    justify-content: center; 
    
}

.content-con:first-child,.content-con:last-child{
    padding: 5rem 0;
    margin-left: auto   ;
}

.content-l img {
    width: 50rem;
    border-radius: 30px;
    margin: auto;
}

.content-r  {
    width:auto;
    padding: 2rem; 
    text-align: center;
}



@media screen and (max-width: 480px){
    .content-con{
     flex-direction: column;
    }
}

.logo {
    height: 80px;
    width: 5rem;
}

.logo_text {
    display: flex;
    align-items: center;

}


.footer_pos {
    background-color: #041B2D;
    left: 0;
    bottom: 0;
    right: 0;
    height: 213px;
}

.footer_text_header {
    font-size: 20px;
    font-weight: 500;

}

.footer_text_size {
    font-size: 14px;

}

.footer_icon_pos {
    margin-top: 55px;
    margin-left: 25px;
    position: fixed;
}

.footer_text_pos {
    font-size: 15px;
    display: grid;
    align-items: center;
    margin-top: 50px;
    margin-left: 60px;
    position: fixed;
}

.footer_text {
    font-size: calc(60% + 0.8vmin);
    display: flex;
    align-items: center;
}

.flexbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    margin: 0px -10px;
}

.item {
    box-sizing: border-box;
    width: 50%;
    margin-bottom: 20px;
    padding: 0px 10px;
}

.content {
    color: #ffffff;
    text-align: start;
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
}

.picturesize {
    height: 21.188rem;
    width: 40rem;
    border-radius: 1.875rem;
}

.picturepartnersize {
    height: 100px;
    width: 100px;
}

.border {
    height: 21.188rem;
    width: 40rem;
    border-radius: 1.875rem;
}

.card {
    height: 250px;
    width: 250px;
    
}

.card:hover {
    transform: scale(1.03);
}

.iconsize{
    height: 110px;
    width: 110px;
}


